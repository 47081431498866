<template>
    <div>

    </div>
</template>
     
<script >
import { wxLogin, getUserInfo } from '@/api/login.js'
export default {
    data() {
        return {
            code: '',
            state: '',
            goRouter: ''//成功后跳转的路由
        }
    },
    created() {
        this.code = this.$route.query.code
        this.state = decodeURIComponent(this.$route.query.state).split('/')[0]
        this.goRouter = '/' + decodeURIComponent(this.$route.query.state).split('/')[1]
        if (this.code) {
            this.wxLogin()
        }
    },
    methods: {
        /**
         * 微信登录
         */
        wxLogin() {
            wxLogin({
                code: this.code,
                state: this.state
            }).then(res => {
                if (res.isBind) {
                    this.setToken(res.token);
                    getUserInfo().then(userInfo => {
                        this.setStorage('userId', userInfo.id);
                        if (this.goRouter === '/login') {
                            this.$router.push({ path: "/" });
                        } else {
                            this.$router.push({ path: this.goRouter });
                        }
                    })
                } else {
                    // this.$store.commit('changeBindCode' , res.bindCode)
                    if (this.goRouter === '/login') {
                        // this.$router.push({ path: "/" });
                        this.$router.push({ path: "/", query: { bindCode: res.bindCode } });
                    } else {
                        this.$router.push({ path: this.goRouter, query: { bindCode: res.bindCode } });
                        // this.$router.push({ path: this.goRouter});
                    }

                }
            })
        }
    }
}
</script>
    